// Color Variables
$primary:  #2e4154;
$black:    #000;
$white:    #fff;

$bt:       #ff5500;
$leech:    #00aee5;
$oldsite:  #0c5;
$redspace: #e72233;
$resulta:  #ff6b00;

$dark-background: #181a1b;
$dark-primary: #b8cad8;
